import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table'
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Packages from "../../components/packages"
import CarouselComponent from "../../components/carousel"
import { Paper } from "@mui/material"


import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Grid, TableBody, TableHead, Typography } from "@mui/material"
import { filterNonNull } from "../../utilities/filterNonNull"


const styles = theme => ({
  root: {
    width: '100%'
  },
  paragraph: {
    marginBottom: '20px'
  },
  halfWidthParagraph:{
    marginBottom: '20px',
    width: '50%',
   /*  [theme.breakpoints.down('sm')]:{
      width: '100%'
    } */
  }

})

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      //backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);


const renderers = {
  table: ({ children }) => (<Paper sx={{ overflowY: 'hidden;', maxWidth: '100%', margin: '0' }} elevation={0}><Table size="small" >{children}</Table></Paper>),
  thead: ({ children }) => (<TableHead sx={{ borderBottom: 1, borderColor: '#ddd' }}>{children}</TableHead>),
  tbody: ({ children }) => <TableBody>{children}</TableBody>,
  tr: ({ children }) =>(<StyledTableRow >{children}</StyledTableRow>),
  td: ({ node, children }) => {
    let propertyObj = {}
    if (node.hasOwnProperty('properties')) {
      propertyObj = filterNonNull(node.properties)
    }
    return (<StyledTableCell sx={{ textAlign: 'center'}} {...propertyObj}>{children}</StyledTableCell>)},
  img: (props) => <img {...props} style={{ maxWidth: '50px' }} />
}


class ProductDetail extends React.Component {

  render() {
    
    const product = get(this.props.data, 'contentfulProduct')
    const { classes } = this.props
    let images = []
    if (product.packagePhoto) {
      images.push(product?.packagePhoto)
    }
    if (product.kibblePhoto) {
      images.push(product?.kibblePhoto)
    }
    if (product.productPhoto) {
      images.push(product?.productPhoto)
    }
    if (product.ambPhoto) {
      images.push(product?.ambPhoto)
    }

    return (
      <Layout location={this.props.location} pageTitle={`${product.brand.brandName} ${product.name}`} pageContext = {this.props.pageContext}>
        <Seo title={`${product.brand.brandName} ${product.name}`} />
        <Typography component='h1' variant='h1'>{`${product.brand.brandName} ${product.name}`}</Typography>

        <Grid container>
          <Grid item md={4} xs={12}>
            <CarouselComponent images={images} />
          </Grid>
          <Grid item md={8} xs={12}>
            <p>{product.teaser}</p>
            <div>
              {product.description}
            </div>
            <ReactMarkdown plugins={[gfm]} components={renderers} rehypePlugins={[rehypeRaw]} children={product.longDescription?.longDescription} />
            <div className={classes.paragraph}>
              <Typography component='span' sx={{ fontWeight: 'bold' }} gutterBottom>Paketler</Typography>
              <Packages skus={product?.sku} />
            </div>
            <div className={classes.paragraph}>
              <Typography component='span' sx={{ fontWeight: 'bold' }} gutterBottom>İçerik</Typography>
              <ReactMarkdown plugins={[gfm]} components={renderers} rehypePlugins={[rehypeRaw]} children={product.ingredients?.ingredients} />
            </div>
            <div className={classes.paragraph}>
              <Typography component='span' sx={{ fontWeight: 'bold' }} gutterBottom>Garanti Analiz</Typography>
              <ReactMarkdown plugins={[gfm]} components={renderers} rehypePlugins={[rehypeRaw]} children={product.analysis?.analysis} />
            </div>
            {product.nutritionAdditives &&
              <div className={classes.paragraph}>
                <Typography component='span' sx={{ fontWeight: 'bold' }} gutterBottom>Besin İlaveleri</Typography>
                <ReactMarkdown plugins={[gfm]} components={renderers} rehypePlugins={[rehypeRaw]} children={product.nutritionAdditives?.nutritionAdditives} />
              </div>
            }
            {product.feedingRecommendation &&
              <div className={classes.paragraph}>
                <Typography component='span' sx={{ fontWeight: 'bold' }} gutterBottom>Besleme Tavsiyesi</Typography>
                <ReactMarkdown plugins={[gfm]} rehypePlugins={[rehypeRaw]} components={renderers} children={product.feedingRecommendation?.feedingRecommendation} />
              </div>
            }
          </Grid>
        </Grid>

      </Layout>
    )

  }

}

export default withStyles(styles, { withTheme: true })(ProductDetail)

export const query = graphql`
 query ProductDetailQuery($productId: String)
 {
    contentfulProduct(id: {eq: $productId}) {
      id
      ageGroup
      analysis {
        analysis
      }
      breeds
      brand {
        brandName
      }
      feedingRecommendation {
        feedingRecommendation
      }
      for_cats
      ingredients {
        ingredients
      }
      longDescription {
        longDescription
      }
      name
      nutritionAdditives {
        nutritionAdditives
      }
      segment
      sku {
        id
        barcode
        cartonAmount
        productCode
        weightInGrams
      }
      teaser
      type
      packagePhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
          }
        }
        
      
      productPhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
      kibblePhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
      ambPhotos {
        id
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
    }
    site {
        siteMetadata{
            siteUrl
          }
    }
      
    
  }
`