import * as React from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const CarouselComponent = (props) => {
    const { images } = props
    return (
        <CarouselProvider
            totalSlides={images.length}
            infinite={true}
            isIntrinsicHeight={true}
            visibleSlides={1}
            dragEnabled={images.length > 1}
            touchEnabled={images.length > 1}
        >

            <Slider>

                {images.map(item => {
                    const image = getImage(item.localFile)
                    return (
                        <>

                            <Slide key={item.id} style={{ margin: 'auto' }}>
                                <GatsbyImage image={image} alt={item.title} />
                            </Slide>



                        </>
                    )
                })}
        
            </Slider>
            <div style={{width:'100%', marginTop:'5%' }}>
                {images.length > 1 &&
                    <div style={{ width: '100%', display:'flex', flexDirection:'row',justifyContent:'center', gap:'5%'}}>
                        <ButtonBack style={{ color: '#666569', backgroundColor: 'transparent', border: 'none', fontSize: '1.3rem', fontWeight: '400' }}>&lt;</ButtonBack>
                        <ButtonNext style={{ color: '#666569',backgroundColor: 'transparent', border: 'none', fontSize: '1.3rem', fontWeight: '400',  }}>&gt;</ButtonNext>
                    </div>
                }
            </div>


        </CarouselProvider>
    )
}

export default CarouselComponent